import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { Dessert, Breakfast, Cookies } from "../utils/imageUpload"
import BodyWrapper from "../components/BodyWrapper"

export const query = graphql`
  query PRODUCTS_LISTING_QUERY {
    allShopifyCollection {
      edges {
        node {
          id
          title
          handle
        }
      }
    }
  }
`

const Card = ({ img, title, handle }) => {
  return (
    <Link to={`/products/${handle}`}>
      <CardStyle>
        <div className="img-container">
          <img width="80%" src={img} alt="" />
        </div>
        <div>
          <h1>{title}</h1>
          <button>Learn More</button>
        </div>
      </CardStyle>
    </Link>
  )
}

const Products = ({ data }) => {
  const collections = data.allShopifyCollection.edges.map(({ node }) => node)

  const FirstMealOfTheMorning = collections.find(
    el => el.handle.toLowerCase() === "breakfast"
  )
  const NomNomNomNom = collections.find(
    el => el.handle.toLowerCase() === "cookies"
  )

  const SaveTheBestForLast = collections.find(
    el => el.handle.toLowerCase() === "desserts"
  )

  return (
    <Layout>
      <SEO title="Product Gallery " />
      <div
        style={{
          backgroundColor: "var(--beige)",
        }}
      >
        <BodyWrapper bg={"var(--beige)"}>
          <CardContainer>
            <Card
              handle={FirstMealOfTheMorning.handle}
              title={FirstMealOfTheMorning.title}
              img={Breakfast}
            />
            <Card
              handle={NomNomNomNom.handle}
              title={NomNomNomNom.title}
              img={Cookies}
            />
            <Card
              handle={SaveTheBestForLast.handle}
              title={SaveTheBestForLast.title}
              img={Dessert}
            />
          </CardContainer>
        </BodyWrapper>
      </div>
    </Layout>
  )
}

export default Products

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
  justify-items: center;
  align-items: center;
  justify-content: center;
  max-width: 1300px;
  margin: 0 auto;
  min-height: 90vh;

  a {
    justify-content: center;
    display: flex;
    text-decoration: none;
  }

  @media (max-width: 1024px) {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: fit-content;

    a {
      padding: 5px;
      margin-bottom: 40px;
      width: 90%;
    }
  }
`
const CardStyle = styled.div`
  text-align: center;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  min-height: 500px;
  width: 70%;
  border-radius: 20px;
  -webkit-box-shadow: 6px 6px 5px -1px rgba(179, 92, 75, 0.5);
  -moz-box-shadow: 6px 6px 5px -1px rgba(179, 92, 75, 0.5);
  box-shadow: 6px 6px 5px -1px rgba(179, 92, 75, 0.5);

  h1 {
    color: var(--darkBlue);
    text-transform: uppercase;
    font-size: calc(2px + 3vw);
    font-weight: bold;
    @media (min-width: 1500px) {
      font-size: 4vh;
    }
  }

  button {
    text-transform: uppercase;
    background-color: var(--pink);
    color: var(--white);
    min-width: 40%;
    padding: 15px 20px;
    border-radius: 25px;
    font-size: 18px;
    font-weight: 700;
    border: none;
    margin: 0.67em 0;
  }

  :hover {
    background-color: var(--pink);
    cursor: pointer;

    h1 {
      color: white;
    }

    button {
      border: 1px solid white;
      color: white;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: row;
    height: 100%;
    min-height: 0;
    padding: 10px 20px;

    width: 100%;

    h1 {
      font-size: calc(12px + 3vw);
    }
    .img-container {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      width: 50%;
    }

    button {
      font-size: 1em;

      padding: 13px 30px;
    }
  }
`
